<template>
  <div class="flix-list-group">
      <router-link v-html="result.title" class="flix-list-group-item flix-html-a" v-for="(result, index) in results" :to="result.link" :key="index"></router-link>
  </div>
</template>
<script>
export default {
  components: {},
  props: { results: Array }
}
</script>
